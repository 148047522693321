import { MAINTAIN_PERMISSION, WEB_ANALYTICS_PPVF_UPDATE } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "update" }, [_c("div", { staticClass: "mx-5 mt-5" }, [_c("a-card", [_c("h2", { staticClass: "import-title mb-3 neutral-10--text" }, [_vm._v("Run PPV Forecast Updates")]), _c("div", { staticClass: "mb-4 neutral-10--text" }, [_vm._v(" Use this page to update PPV Forecast Model. "), _c("br"), _vm._v("Use the Country drop down for all except Refresh Cube. ")]), _c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 5 } }, [_c("div", { staticClass: "d-flex align-items-center" }, [_c("span", { staticClass: "neutral-10--text mr-2" }, [_vm._v("Country")]), _c("div", { staticClass: "country-filter" }, [_c("select-input", { attrs: { "source": "country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "placeholder": "Select Country", "disabled": !_vm.can(_vm.permissions.maintain) }, on: { "change": _vm.onCountryChange } })], 1)])]), _c("a-col", { attrs: { "span": 5 } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "mb-2", attrs: { "span": 24 } }, [_c("a-button", { staticClass: "w-100", attrs: { "type": "primary", "disabled": !_vm.can(_vm.permissions.maintain) }, on: { "click": function($event) {
    return _vm.onClick("runjob-download-bpcs");
  } } }, [_vm._v("Download from BPCS")])], 1), _c("a-col", { staticClass: "mb-2", attrs: { "span": 24 } }, [_c("a-button", { staticClass: "w-100", attrs: { "type": "primary", "disabled": !_vm.can(_vm.permissions.maintain) }, on: { "click": function($event) {
    return _vm.onClick("runjob-calc-ppvfw");
  } } }, [_vm._v("Calculate PPV Forecast")])], 1), _c("a-col", { staticClass: "mb-2", attrs: { "span": 24 } }, [_c("a-button", { staticClass: "w-100", attrs: { "type": "primary", "disabled": !_vm.can(_vm.permissions.maintain) }, on: { "click": function($event) {
    return _vm.onClick("runjob-refresh-cube");
  } } }, [_vm._v("Rebuild Cube")])], 1), _c("a-col", { staticClass: "mb-2", attrs: { "span": 24 } }, [_c("a-button", { staticClass: "w-100", attrs: { "type": "primary", "disabled": !_vm.can(_vm.permissions.maintain) }, on: { "click": function($event) {
    return _vm.onClick("runjob-archive");
  } } }, [_vm._v("Archive")])], 1)], 1)], 1)], 1)], 1)], 1), _c("list-composer", { attrs: { "has-active": false, "has-filter-inactive": false } }, [_c("text-field", { key: "VersionID", attrs: { "data-index": "versionID", "title": "Version ID" } }), _c("text-field", { key: "CountryCode", attrs: { "data-index": "countryCode", "title": "Country Code" } }), _c("text-field", { key: "VersionDate", attrs: { "data-index": "versionDate", "title": "Version Date", "parse": _vm.parseDate } }), _c("text-field", { key: "ArchivedDate", attrs: { "data-index": "archivedDate", "title": "Archived Date", "parse": _vm.parseDate } }), _c("text-field", { key: "VersionAlias", attrs: { "data-index": "versionAlias", "title": "Version Alias" } })], 1)], 1);
};
var staticRenderFns$1 = [];
var Update_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".update .country-filter[data-v-6400cf4c]{min-width:160px}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "Update",
  inject: ["can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      apiUrl: apiUrl$1,
      isDownloadLoading: false,
      isCalculateLoading: false,
      isRebuildLoading: false,
      isArchiveLoading: false,
      Country: "",
      ProcSpecId: "",
      fileName: "",
      userLocation: "",
      confirmRebuildCube: "Are you sure you want to update the ANZ PPV Forecast cube?",
      confirmArchive: "Are you sure you want to archive the PPV Forecast version?  If you have already run the archive function for this period, the previous version will be overwritten."
    };
  },
  created() {
    this.getCountries();
  },
  methods: {
    onCountryChange(value) {
      if (value) {
        this.Country = value;
      }
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(`${value}`).format("YYYY/MM/DD hh:mm:ss A");
    },
    onClick(value) {
      if (!this.Country) {
        this.$notification.error({
          message: "Country must be chosen."
        });
      } else if (value === "runjob-refresh-cube" || value === "runjob-archive") {
        var confirmTitle = "";
        if (value === "runjob-refresh-cube") {
          confirmTitle = this.confirmRebuildCube;
        }
        if (value === "runjob-archive") {
          confirmTitle = this.confirmArchive;
        }
        this.confirmPopup(confirmTitle, value);
      } else {
        this.onConfirmClick(value);
      }
    },
    confirmPopup(title, value) {
      this.$confirm({
        class: "list__modal-confirm",
        title,
        cancelText: "Cancel",
        okText: "OK",
        okType: "primary",
        onOk: () => this.onConfirmClick(value)
      });
    },
    onConfirmClick(value) {
      this.axios.post(`${this.apiUrl}/web-analytics/versions/${value}`, {
        Country: this.Country
      }).then((res) => {
        this.$notification["success"]({
          message: res.data.message
        });
      }).catch(() => {
        this.$notification.error({
          message: "Something went wrong, please try again"
        });
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6400cf4c", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Update = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.versions", "api-url": _vm.apiUrl, "resource-id-name": "versionID", "edit-route": "/web-analytics/update/:id", "page": _vm.page } }, [_c("update")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Update
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_UPDATE,
      Update,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
